import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe, LoginUserEmis, ValidasiToken } from "../features/authSlice";
import { ref, onValue } from "firebase/database";
import database from "../features/firebaseConfig";
import { useUnreadMessages } from '../UnreadMessagesContext';



const UnreadMessagesListener = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    dispatch(LoginUserEmis());
  }, [dispatch]);

  useEffect(() => {
    dispatch(ValidasiToken());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);




  const { setUnreadMessages } = useUnreadMessages();
  const messagesDataRef = useRef(null);
  const namestore = localStorage.getItem('name');
  const userId = namestore;

  useEffect(() => {
    const messagesRef = ref(database, "messages");
    onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      messagesDataRef.current = data;
      let unreadCount = 0;

      if (data) {
        Object.values(data).forEach(message => {
          if (!message.readBy || !message.readBy.includes(userId)) {
            unreadCount++;
          }
        });
      }

      if (document.hidden) {
        setUnreadMessages(unreadCount);
      }
    });


    return () => {
      // window.removeEventListener('focus', handleFocus);
    };
  }, [setUnreadMessages, userId]);

  return null;
};

export default UnreadMessagesListener;