import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";



const firebaseConfig = {
  apiKey: "AIzaSyDVMw524q8F1YPg0OYsl-JDLTm-TulKfJA",
  authDomain: "saktichat-90822.firebaseapp.com",
  databaseURL: "https://saktichat-90822-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "saktichat-90822",
  storageBucket: "saktichat-90822.firebasestorage.app",
  messagingSenderId: "141607757352",
  appId: "1:141607757352:web:2193647fe8d5e3541db71d",
  measurementId: "G-H5JNZBWKN7"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export default database;
