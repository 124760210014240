import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";
import { ref, set } from "firebase/database";
import database from "./firebaseConfig";

const initialState = {
  user: null,
  emis: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
  isLoadingEmis: false,
  isErrorEmis: false,
  isSuccessEmis: false,
  message: "",
  messageEmis: "",
};

const apiUrl = "https://api.sakti.biz.id/api/";
// const apiUrl = "http://localhost:8000/api/";

export const LoginUser = createAsyncThunk(
  "auth/LoginUser",
  async (user, thunkAPI) => {
    try {
      const response = await axios.post(
        apiUrl + "login",
        {
          nsm: user.nsm,
          password: user.password,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );


      localStorage.setItem("token", response.data.token);
      localStorage.setItem("nsm", response.data.user.nsm);
      return response.data;
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        // console.log(message);
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

// send token sso
export const LoginUserEmis = createAsyncThunk(
  "user/LoginUserEmis",
  async (_, thunkAPI) => {
    const response = await axios.get(
      "https://api-emis.kemenag.go.id/v1/accounts/sso/signal",
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = response.data;
    const jsonResponse = JSON.stringify(data);
    const aaa = JSON.parse(jsonResponse);
    let token = aaa.substr(26, 32);
    localStorage.setItem("sso_token", token);

  }
);

// INI VALIDASI TOKEN
export const ValidasiToken = createAsyncThunk(
  "emis/ValidasiToken",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const ssotoken = localStorage.getItem("sso_token");
    var bodyFormData = new FormData();
    bodyFormData.append("sso_token", ssotoken);
    const send = await axios({
      method: "post",
      url: "https://api.sakti.biz.id/api/token",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(function (resp) {
      localStorage.setItem("bear", resp.data.token);
      getMeEmis();
      return resp.data;
    });
  }
);

async function getMeEmis() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(
      apiUrl + "eme",
      {
        bear: localStorage.getItem("bear"),
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // console.log(response);
    if (response.data.results !== null) {
      localStorage.setItem(
        "institution",
        JSON.stringify(response.data.results.institutions)
      );

      localStorage.setItem('category_id', response.data.results.institution.category_id)
      localStorage.setItem('npsn', response.data.results.institutions[0].npsn)

    } else {
      if (
        response.data.errors.message ===
        "You are not authorized to access this resource."
      ) {
        Swal.fire({
          icon: "error",
          title: "EMIS",
          text: "Belum terkoneksi dengan EMIS",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.errors.message,
        });
      }
    }
  } catch (error) {
    if (error.response) {
      const message = error.response.data.message;
    }
  }
}

export const getMe = createAsyncThunk("user/getMe", async (_, thunkAPI) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(apiUrl + "me", {
      headers: { Authorization: `Bearer ${token}` },
    });

    // Set user as online in Firebase

    console.log('ini namaku', response.data.user.name)

    const userStatusRef = ref(database, `onlineUsers/${response.data.user.name}`);
    set(userStatusRef, true);

    localStorage.setItem('name', response.data.user.name)

    return response.data;
  } catch (error) {
    if (error.response) {
      const message = error.response.data.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
});

export const LogOut = createAsyncThunk("user/LogOut", async () => {
  const token = localStorage.getItem("token");
  const response = await axios.post(apiUrl + "logout", {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(LoginUser.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(LoginUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.user = action.payload;
    });

    builder.addCase(LoginUser.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });

    // Get Me
    builder.addCase(getMe.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getMe.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.user = action.payload;
    });

    builder.addCase(getMe.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });

    // Login User EMIS
    builder.addCase(ValidasiToken.pending, (state) => {
      state.isLoadingEmis = true;
    });

    builder.addCase(ValidasiToken.fulfilled, (state, action) => {
      state.isLoadingEmis = false;
      state.isSuccessEmis = true;
      state.emis = action.payload;
    });

    builder.addCase(ValidasiToken.rejected, (state, action) => {
      state.isLoadingEmis = false;
      state.isErrorEmis = true;
      state.messageEmis = action.payload;
    });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
