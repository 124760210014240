import React from 'react';
import './Preloader.css'; // Ensure this file contains your custom styles

function Preloader() {
  return (
    <div className="preloader">
      <div className="favicon-spinner"></div>
      <p>Loading...</p>
    </div>
  );
}

export default Preloader;